import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as qualityAssuranceActions from '../redux/modules/qualityAssurance';
import * as flashMessageActions from '../redux/modules/flashMessage';
import * as C from '../utils/constants';
import ApiClient from '../utils/apiClient';
import {_} from '../locales/gettext';
import ComponentHelpers from '../utils/componentHelpers';
import QualityAssuranceSelect from './qualityAssuranceSelect';
import QualityAssuranceEntity from "../utils/qualityAssuranceEntity";
import QualityAssuranceItemSelect from "./qualityAssuranceItemSelect";
import QualityAssuranceItem from "./qualityAssuranceItem";
import {getAuthStruct} from "../utils/auth";

export default connect(
    (state, props) => {
        return {
            qualityAssurance: state.qualityAssurance,
        }
    },
    (dispatch) => {
        return {
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class QualityAssurance extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                qualityAssuranceJobs: null,
            };

            this.api = new ApiClient(this.props);
            this.qualityAssuranceEntity = new QualityAssuranceEntity(this.props.qualityAssurance);
            this.componentHelpers = new ComponentHelpers(this, this.api);
        }


        componentDidMount() {
            this.loadJobs();
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
            this.qualityAssuranceEntity.setState(this.props.qualityAssurance);
        }


        setErrorMessage(error, callback, fullScreen = true) {
            this.componentHelpers.setErrorMessage(error, callback, fullScreen);
            this.setState({pending: false});
        }


        async loadJobs() {
            await this.componentHelpers.handleAsyncError(async () => {
                const response = await this.api.get(C.qaGroup + '/jobs');
                this.setState({
                    qualityAssuranceJobs: response
                });
            }, null, false);
        }


        async startItem(serial_number) {
            const qualityAssurance = this.props.qualityAssurance;
            const item = this.qualityAssuranceEntity.findItem(serial_number);
            if(item) {
                await this.componentHelpers.handleAsyncError(async () => {
                    let response = item;
                    if(!item.new) {
                        response = await this.api.get(C.qaGroup + '/jobs/' + qualityAssurance.code + '/subjects/' + item.id);
                    }
                    const auth = getAuthStruct();
                    if(!response.inspections) {
                        response.inspections = [];
                    }
                    response.inspections.unshift({
                        id: null,
                        new: true,
                        start: {
                            iso: (new Date().toISOString()),
                        },
                        end: null,
                        operator: {
                            id: auth.data.id,
                            name: auth.data.firstname + ' ' + auth.data.lastname,
                        },
                        results: [],
                    });
                    this.props.qualityAssuranceActions.updateItem(serial_number, response);
                    this.props.qualityAssuranceActions.setCurrentItem(serial_number);
                }, null, false);
            } else {
                this.componentHelpers.setErrorMessage({message: _('Item not found')}, null, false);
            }
        }


        filterSubjects(subjects) {
            let filterValue = this.props.qualityAssurance.positionFilter ? this.props.qualityAssurance.positionFilter.toLowerCase().trim() : '';
            return subjects.map((subject) => {
                if(filterValue == '') {
                    subject.filtered = false;
                } else {
                    let position = subject.label ? subject.label.toLowerCase().trim() : '';
                    subject.filtered = position.indexOf(filterValue) < 0;
                }
                return subject;
            });
        }


        render() {
            let content;
            const qualityAssurance = this.props.qualityAssurance;
            this.qualityAssuranceEntity.setState(qualityAssurance);

            if(qualityAssurance) {
                const qualityAssuranceItem = this.qualityAssuranceEntity.getCurrentItem();
                if(qualityAssuranceItem) {
                    const collectInputOnDefectAbsence = qualityAssurance.settings && typeof qualityAssurance.settings.collectInputOnDefectAbsence !== 'undefined' &&
                    qualityAssurance.settings.collectInputOnDefectAbsence !== null ? qualityAssurance.settings.collectInputOnDefectAbsence : false;
                    content = <QualityAssuranceItem
                        data={qualityAssuranceItem}
                        defects={qualityAssurance.defects}
                        statesWithoutDocuments={collectInputOnDefectAbsence ? [] : [C.qaDefectStatus.ABSENT]}
                        onAddDefect={(serialNumber, defect) => this.props.qualityAssuranceActions.addDefect(serialNumber, defect)}
                    />;
                } else {
                    const fixedSubjectList =  Boolean(qualityAssurance.settings && typeof qualityAssurance.settings.fixedSubjectList !== 'undefined' && !!qualityAssurance.settings.fixedSubjectList);
                    content = <QualityAssuranceItemSelect
                        data={this.filterSubjects(qualityAssurance.subjects)}
                        defects={qualityAssurance.defects}
                        fixedSubjectList={fixedSubjectList}
                        filterValue={{
                            position: qualityAssurance.positionFilter || ''
                        }}
                        onStart={(serial_number) => {
                            this.startItem(serial_number);
                        }}
                        onAdd={(serial_number, position) => {
                            this.props.qualityAssuranceActions.addItem(serial_number, position);
                        }}
                        onRemove={(serial_number) => {
                            this.props.qualityAssuranceActions.removeItem(serial_number);
                        }}
                        onCheck={(serial_number, checked) => {
                            this.qualityAssuranceEntity.setCurrent(null);
                        }}
                        onPositionFilter={(value) => {
                            this.props.qualityAssuranceActions.setPositionFilter(value);
                        }}
                    />;
                }
            } else if(this.state.qualityAssuranceJobs) {
                content = <QualityAssuranceSelect
                    data={this.state.qualityAssuranceJobs}
                    onClose={() => {
                        this.props.history.replace(C.routes.HOMEPAGE);
                    }}
                    onStart={async (jobCode) => {
                        await this.componentHelpers.handleAsyncError(async () => {
                            const response = await this.api.get(C.qaGroup + '/jobs/' + jobCode);
                            this.qualityAssuranceEntity.setState(response);
                            this.props.qualityAssuranceActions.set(this.qualityAssuranceEntity.getState(true));
                        }, null, false);
                    }}
                />
            } else {
                content = <h4>
                    {_('Loading available EOL jobs...')}
                </h4>;
            }

            return content;
        }
    }
)
