import React from 'react';
import Form from './form';
import UserInput from './userInput';
import {_} from '../locales/gettext';


export default class Login extends Form {

    static defaultProps = {
        submitText: _('Sign in'),
        title: _('Welcome to Q&L CRM system'),
    };

    render() {
        let inputs = <div>
            <UserInput
                ref="login"
                check={this.state.submitCheck}
                onScanSubmit={() => this.submit()}
            />
        </div>;

        return this.getForm(inputs);
    }

}
