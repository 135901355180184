const SET = '@batch/SET';
const CLEAR = '@batch/CLEAR';

const batch = (state = null, action) => {
    let result = JSON.parse(JSON.stringify(state));

    switch (action.type) {

        case SET:
            return action.state;

        case CLEAR:
            return null;

        default:
            return state
    }
}

export default batch


export const set = (state) => ({
    type: SET,
    state
});


export const clear = () => ({
    type: CLEAR,
});
