import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {_} from '../locales/gettext';
import FaIcon from './faIcon';
import classNames from "classnames";
import UserCheck from "../forms/userCheck";
import ComponentHelpers from '../utils/componentHelpers';
import ApiClient from "../utils/apiClient";
import {connect} from "react-redux";
import {bindActionCreators} from "../redux/bindActionCreators";
import * as unfinishedWorksheetsActions from "../redux/modules/unfinishedWorksheets";
import * as flashMessageActions from "../redux/modules/flashMessage";

export default connect(
    (state, props) => {
        return {
            unfinishedWorksheets: state.unfinishedWorksheets
        }
    },
    (dispatch) => {
        return {
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class Unfinished extends React.Component {
        static propTypes = {
            onSelect: PropTypes.func.isRequired,
        };


        constructor(props) {
            super(props);

            this.state = {
                cancel: null,
            };

            this.api = new ApiClient(this.props);
            this.componentHelpers = new ComponentHelpers(this, this.api);
        }


        handleWorksheet(worksheet, partTitle) {
            this.props.onSelect({partTitle: partTitle, ...worksheet});
        }


        handleCancel(worksheet) {
            this.setState({cancel: worksheet});
        }


        renderDate(date) {
            return moment(date).format('D.M.YYYY, HH:mm')
        }


        render() {
            let rows = [];

            this.props.unfinishedWorksheets.forEach((item, key) => {
                let partTitles = [];
                let jobTitles = [];

                item.jobs.forEach((jobId) => {
                    if(item.refs[jobId]) {
                        jobTitles.push(item.refs[jobId].job.title);
                        if(item.refs[jobId].part_name && partTitles.indexOf(item.refs[jobId].part_name) < 0) {
                            partTitles.push(item.refs[jobId].part_name);
                        }
                    }
                });

                if(!partTitles.length) {
                    partTitles.push(_('N/A'));
                }

                const partTitle = partTitles.join(', ');

                rows.push(<tr key={key}>
                    <td>{item.part_number}</td>
                    <td>{partTitle}</td>
                    <td>{this.renderDate(item.updated)}</td>
                    <td>
                        {jobTitles.map((jobTitle, key) => {
                            return <div key={key} style={{lineHeight: '150%'}}>{jobTitle}</div>;
                        })}
                    </td>
                    {/*<td>
                        <button className={classNames({smallButton: true, pointer: true})} onClick={() => this.handleCancel(item)}>
                            <FaIcon icon="times" />
                        </button>
                    </td>*/}
                    <td>
                        <button className={classNames({smallButton: true, pointer: true})} onClick={() => this.handleWorksheet(item, partTitle)}>
                            <FaIcon icon="arrow-right" />
                        </button>
                    </td>
                </tr>);
            });

            return <div>
                <h2 className="scanHide">
                    {_('Unfinished works')}:
                </h2>
                <table className="jobsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                    <tbody>
                        <tr key="heading" className="headRow">
                            <th>{_('Part Number')}</th>
                            <th>{_('Title')}</th>
                            <th>{_('Last Update')}</th>
                            <th>{_('Jobs')}</th>
                            {/*<th>{_('Cancel (inform TeamLeader)')}</th>*/}
                            <th>{_('Continue')}</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
                {
                    !!this.state.cancel &&
                    <div style={{marginTop: '3em'}}>
                        <UserCheck
                            title={_('For cancel unfinished work inform your TeamLeader')}
                            alert={this.props.alert ? <div className="flashMessage alert"><div className="inner">{this.props.alert}</div></div> : null}
                            onSubmit={async (result) => {
                                await this.componentHelpers.checkTL(result, this.state.cancel.jobs, async () => {
                                    this.props.unfinishedWorksheetsActions.remove(this.state.cancel.id);
                                    this.setState({cancel: null});
                                });
                            }}
                        />
                    </div>
                }
            </div>;
        }

    }
)
