const SET = '@flashmessage/SET';
const CLEAR = '@flashmessage/CLEAR';

const flashMessage = (state = null, action) => {
    switch (action.type) {

        case SET:
            return action.message;

        case CLEAR:
            return null;

        default:
            return state
    }
}

export default flashMessage

export const set = (message) => ({
    type: SET,
    message,
});

export const clear = () => ({
    type: CLEAR,
});