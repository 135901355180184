const ADD_JOB = '@job/ADD_JOB';
const CLEAR = '@job/CLEAR';
const ADD_BATCH = '@job/ADD_BATCH';
const ADD_DEFECT_PHOTO_SERIE = '@job/ADD_DEFECT_PHOTO_SERIE';

const defaultResult = {
    jobs: [],
    requiredBatch: false,
    requiredBoxNumber: false,
    finishedBatches: [],
    defectPhotoSeries: {},
};

const work = (state = null, action) => {
    let result = JSON.parse(JSON.stringify(state));

    switch (action.type) {

        case ADD_JOB:
            if(!result) {
                result = JSON.parse(JSON.stringify(defaultResult));
            }

            result.jobs.forEach((item, key) => {
                if(item.id == action.job.id) {
                    result.jobs.splice(key, 1);
                }
            });

            result.jobs.push(action.job);

            if(action.job.track_batch_numbers) {
                result.requiredBatch = true;
            }

            if(action.job.worksheet_settings && action.job.worksheet_settings.require_box_number) {
                result.requiredBoxNumber = true;
            }

            return result;

        case ADD_BATCH:
            if(!result) {
                result = JSON.parse(JSON.stringify(defaultResult));
            }

            result.finishedBatches.push(action.batch);
            return result;

        case ADD_DEFECT_PHOTO_SERIE:
            if(!result) {
                result = JSON.parse(JSON.stringify(defaultResult));
            }

            if(!result.defectPhotoSeries[action.defectId]) {
                result.defectPhotoSeries[action.defectId] = 0;
            }

            result.defectPhotoSeries[action.defectId] += action.count;
            return result;

        case CLEAR:
            return null;

        default:
            return result
    }
}

export default work


export const addJob = (job) => ({
    type: ADD_JOB,
    job,
});

export const clear = () => ({
    type: CLEAR,
});

export const addBatch = (batch) => ({
    type: ADD_BATCH,
    batch,
});

export const addDefectPhotoSerie = (defectId, count = 1) => ({
    type: ADD_DEFECT_PHOTO_SERIE,
    defectId,
    count,
});
