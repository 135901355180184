import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Reader from './qrReader/index';
import Quagga from 'quagga';
import Tooltip from 'react-tooltip-lite';
import FaIcon from './faIcon';
import {_} from '../locales/gettext';


export default class CodeReader extends React.Component {

    static propTypes = {
        onScan: PropTypes.func.isRequired,
        tooltip: PropTypes.string,
        defaultType: PropTypes.string,
        allowBarCode: PropTypes.bool,
    };


    static defaultProps = {
        tooltip: _('Record Code'),
        defaultType: 'BAR',
        allowBarCode: true,
    };


    constructor(props) {
        super(props);

        this.initState = {
            error: null,
            openScan: false,
            canScan: false,
            type: props.defaultType,
        };

        this.state = this.initState;
    }


    componentDidMount() {
    }


    componentWillUnmount() {
        this.showElements();
        try { Quagga.stop(); } catch(err) {}
        document.getElementById('quagga').classList.add('hidden');
    }


    hideElements() {
        [].forEach.call(document.querySelectorAll('.flashMessage'), function (el) {
            el.style.backgroundColor = 'transparent';
        });
        [].forEach.call(document.querySelectorAll('.scanHide'), function (el) {
            el.style.visibility = 'hidden';
        });
    }



    showElements() {
        [].forEach.call(document.querySelectorAll('.flashMessage'), function (el) {
            el.style.backgroundColor = '';
        });
        [].forEach.call(document.querySelectorAll('.scanHide'), function (el) {
            el.style.visibility = 'visible';
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.state.openScan) {
            this.hideElements();
        } else {
            this.showElements();
        }

        if(this.state.openScan && this.state.type === 'BAR') {
                document.getElementById('quagga').classList.remove('hidden');

                if(!this.state.canScan) {
                    Quagga.init({
                        inputStream: {
                            type : "LiveStream",
                            constraints: {
                                width: 640,
                                height: 480,
                                facingMode: "environment",
                                aspectRatio: 1,
                                zoom: 1,
                                deviceId: window.deviceId ? window.deviceId : null,
                            },
                            area: {
                                top: "40%",
                                right: "0%",
                                left: "0%",
                                bottom: "40%",
                            },
                            target: document.querySelector('#quagga')
                        },
                        locator: {
                            patchSize: "medium",
                            halfSample: true,
                        },
                        numOfWorkers: 2,
                        decoder: {
                            readers : [
                                "code_128_reader",
                                "code_39_reader",
                            ]
                        },
                        locate: true,
                    }, function(err) {
                        if (err) {
                            return;
                        }

                        Quagga.start();

                        const track = Quagga.CameraAccess.getActiveTrack();
                        if (track && typeof track.getCapabilities === 'function') {
                            track.applyConstraints({
                                advanced: [{torch: true}]
                            }).catch((err) => { });
                        }

                        const height = document.getElementById('quaggaContainer').offsetHeight - 4;
                        localStorage.setItem('quaggaHeight', height);
                        document.getElementById('quaggaReactContainer').setAttribute('style', 'height: ' + height + 'px');
                    });

                    Quagga.onProcessed(function(result) {
                        var drawingCtx = Quagga.canvas.ctx.overlay, drawingCanvas = Quagga.canvas.dom.overlay;

                        if (result) {
                            if (result.boxes) {
                                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                            }

                            if (result.codeResult && result.codeResult.code) {
                                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: '#00ff00', lineWidth: 5});
                            }
                        }
                    });

                    Quagga.onDetected((data) => this.handleDetect(data));
                }

        } else {
            document.getElementById('quagga').classList.add('hidden');
            try { Quagga.stop(); } catch(err) {}
        }
    }


    handleScan(data) {
        if(data && this.state.canScan) {
            this.setState(this.initState);
            this.props.onScan(data);
        }
    }


    handleError(error) {
        let message = _('Scan error, please try again');
        if(error && error.message && error.message == 'Permission denied') {
            message = _('Scan error, please allow camera in your browser');
        }
        this.setState({
            canScan: false,
            error: message,
        });
    }


    handleDetect(data) {
        if(data && this.state.canScan) {
            this.props.onScan(data.codeResult.code);
            this.setState(this.initState);
        }
    }


    changeType(type) {
        this.setState({
            canScan: false,
            type: type,
        });
    }


    openScan(open) {
        this.setState({
            canScan: false,
            openScan: open,
        });
    }


    canScan() {
        this.setState({
            canScan: true,
        });
    }


    render() {

        if(this.state.openScan) {
            return <div id="codeReader">
                <div className="inner">
                    <div className="close">
                        <div onClick={() => { this.openScan(false); }}>
                            <FaIcon icon="times" />
                        </div>
                    </div>
                    <div className="scanner">
                        {
                            !this.state.canScan &&
                            <div className="overlay" onClick={() => this.canScan()}>
                                <span className="icon">
                                    <FaIcon icon="camera" />
                                </span>
                            </div>
                        }

                        <div className={classNames({hidden: this.state.type === 'BAR'})}>
                            <Reader
                                delay={100}
                                onError={(error) => this.handleError(error)}
                                onScan={(data) => this.handleScan(data)}
                                style={{width: "100%"}}
                                constraints={{
                                    facingMode: 'environment',
                                    deviceId: window.deviceId ? window.deviceId : null,
                                }}
                            />
                        </div>

                        <div
                            id="quaggaReactContainer"
                            className={classNames({hidden: this.state.type === 'QR'})}
                            style={{height: (localStorage.getItem('quaggaHeight') ? localStorage.getItem('quaggaHeight') + 'px' : '200px')}}
                        >
                            <div className="topOverlay"></div>
                            <div className="bottomOverlay"></div>
                            <div className="leftOverlay"></div>
                            <div className="rightOverlay"></div>
                        </div>

                    </div>

                    <div className="line">
                        <div>{this.state.error}</div>
                        <b>
                            {_('Scan')}
                            &nbsp;{this.state.type}
                            &nbsp;{_('Code')}
                        </b>

                        <div className="icons">
                            <Tooltip content="QR code" className={classNames({target: true, hidden: this.state.type === 'QR'})}>
                                <span className="anchor iconAnchor codeSwitch" onClick={() => this.changeType('QR')}>
                                    <FaIcon icon="qrcode" />
                                </span>
                            </Tooltip>

                            {
                                this.props.allowBarCode &&
                                <Tooltip content="BAR code" className={classNames({target: true, hidden: this.state.type === 'BAR'})}>
                                    <span className="anchor iconAnchor codeSwitch" onClick={() => this.changeType('BAR')}>
                                        <FaIcon icon="barcode" />
                                    </span>
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </div>;
        } else {
            return <Tooltip content={this.props.tooltip} className="target scanIcon scanHide">
                <span className="anchor iconAnchor" onClick={() => this.openScan(true)}>
                    <FaIcon icon="camera" />
                </span>
            </Tooltip>;
        }
    }
}
