import * as C from "../../utils/constants";

const SET = '@qualityAssurance/SET';
const SET_COMMENT = '@qualityAssurance/SET_COMMENT';
const SET_POSITION_FILTER = '@qualityAssurance/SET_POSITION_FILTER';
const SET_CURRENT_ITEM = '@qualityAssurance/SET_CURRENT_ITEM';
const UPDATE_ITEM = '@qualityAssurance/UPDATE_ITEM';
const ADD_ITEM = '@qualityAssurance/ADD_ITEM';
const REMOVE_ITEM = '@qualityAssurance/REMOVE_ITEM';
const ADD_DEFECT = '@qualityAssurance/ADD_DEFECT';
const CLEAR = '@qualityAssurance/CLEAR';

const qualityAssurance = (state = null, action) => {
    let result = JSON.parse(JSON.stringify(state));

    switch (action.type) {

        case SET:
            result = action.job;
            return result;

        case SET_COMMENT:
            result.notice = action.comment;
            return result;

        case SET_POSITION_FILTER:
            result.positionFilter = action.value;
            return result;

        case SET_CURRENT_ITEM:
            result.subjects.forEach((item) => {
                item.current = false;
                if(action.serialNumber == item.serial_number) {
                    item.current = true;
                }
            });
            return result;

        case UPDATE_ITEM:
            for(let key in result.subjects) {
                if(action.serialNumber == result.subjects[key].serial_number) {
                    result.subjects[key] = {...result.subjects[key], ...action.data};
                }
            }
            return result;

        case ADD_ITEM:
            result.subjects.push({
                id: null,
                serial_number: action.serialNumber,
                status: C.qaStatus.NOT_CHECKED,
                label: action.position,
                inspections: [],
                new: true,
            });
            return result;

        case REMOVE_ITEM:
            for(let key in result.subjects) {
                if(action.serialNumber == result.subjects[key].serial_number) {
                    result.subjects.splice(key, 1);
                }
            }
            return result;

        case ADD_DEFECT:
            let found;
            for(let key in result.subjects) {
                if(action.serialNumber == result.subjects[key].serial_number) {
                    for(let key2 in result.subjects[key].inspections) {
                        if(result.subjects[key].inspections[key2].new) {
                            result.subjects[key].inspections[key2].results.unshift(action.defect);
                            if(!result.subjects[key].result) {
                                result.subjects[key].result = {};
                            }
                            if(!result.subjects[key].result.concerns) {
                                result.subjects[key].result.concerns = [];
                            }

                            found = false;
                            for(let key3 in result.subjects[key].result.concerns) {
                                if(result.subjects[key].result.concerns[key3].defect == action.defect.id) {
                                    result.subjects[key].result.concerns[key3].state = action.defect.state;
                                    found = true;
                                }
                            }
                            if(!found) {
                                result.subjects[key].result.concerns.push({
                                    defect: action.defect.id,
                                    state: action.defect.state,
                                });
                            }
                        }
                    }
                }
            }
            return result;

        case CLEAR:
            return null;

        default:
            return result
    }
}

export default qualityAssurance


export const set = (job) => ({
    type: SET,
    job,
});


export const clear = () => ({
    type: CLEAR,
});


export const setComment = (comment) => ({
    type: SET_COMMENT,
    comment,
});


export const setPositionFilter = (value) => ({
    type: SET_POSITION_FILTER,
    value,
});


export const setCurrentItem = (serialNumber) => ({
    type: SET_CURRENT_ITEM,
    serialNumber,
});


export const updateItem = (serialNumber, data) => ({
    type: UPDATE_ITEM,
    serialNumber,
    data,
});


export const addItem = (serialNumber, position) => ({
    type: ADD_ITEM,
    serialNumber,
    position,
});


export const removeItem = (serialNumber) => ({
    type: REMOVE_ITEM,
    serialNumber,
});


export const addDefect = (serialNumber, defect) => ({
    type: ADD_DEFECT,
    serialNumber,
    defect,
});
