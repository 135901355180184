import request from 'superagent';
import * as flashMessageActions from '../redux/modules/flashMessage';
import * as C from './constants';
import {getAuthStruct} from './auth';
import {getId} from '../utils/auth';
import {getLang} from '../locales/locale';
import {_} from '../locales/gettext';

export default class ApiClient {

    apiUrl = process.env.REACT_APP_API_URL;
    loadingTimeout = null;
    userId = getId();


    constructor(parentProps = null) {
        this.parentProps = parentProps;
    }

    // Poznamka: tieto metody "whole response" su tu dorobene expt-post a je to naprdel. V tomto momente sa mi nechce prekopavac celu aplikaciu, aby som to vyriesil.

    async authenticate(values) {
        const method = values.token ? 'session/exchange' : 'session';
        const params = values.token ? {token: values.token, scope: C.scope} : {username: values.username, password: values.password, scope: C.scope};
        return await this.__callWholeResponse('POST', method, params);
    }


    async post(endpoint, params) {
        return await this.call('POST', endpoint, params);
    }


    async get(endpoint, params) {
        return await this.call('GET', endpoint, params);
    }


    async put(endpoint, params) {
        return await this.call('PUT', endpoint, params);
    }


    async patch(endpoint, params) {
        return this.call('PATCH', endpoint, params);
    }


    async call(method, endpoint, params) {
        const auth = getAuthStruct();
        return await this.__call(method, endpoint, params, auth ? auth.token : null);
    }

    async callWholeResponse(method, endpoint, params) {
        const auth = getAuthStruct();
        return await this.__callWholeResponse(method, endpoint, params, auth ? auth.token : null);
    }

    async __callWholeResponse(method, endpoint, params, token = null) {
        this.__requestStarted();

        if(token) {
            token = 'bearer ' + token;
        }

        return new Promise(async (accept, reject) => {
            try {
                const response = await request(method, this.apiUrl + '/' + endpoint)
                    .set('Authorization', token)
                    .set('X-Version', C.API_VERSION) // @todo vymyslet verzovani
                    .set('X-Lang', getLang())
                    .send(params);

                this.__requestFinished();
                if(response.body && response.body.error) {
                    reject({...response.body.error, api: true});
                } else if(response.body) {
                    accept(response.body);
                } else {
                    reject({
                        message: _('Bad API Server Response'),
                        code: C.errors.BAD_RESPONSE,
                        api: true,
                    });
                }
            } catch(error) {
                this.__requestFinished();
                if(error.response && error.response.body && error.response.body.error) {
                    reject({...error.response.body.error, api: true});
                } else {
                    reject({
                        message: _('Bad API Server Response'), // tuna to pada
                        code: C.errors.BAD_RESPONSE,
                        api: true,
                    });
                }
            }
        });
    }

    async __call(method, endpoint, params, token = null) {
        const body = await this.__callWholeResponse(method, endpoint,params, token)
        return body.data ? body.data : {}
    }


    __requestStarted() {
        if(this.parentProps) {
            try {
                this.parentProps.dispatch(flashMessageActions.clear());
            } catch(err) {

            }
        }

        this.loadingTimeout = setTimeout(function() {
            let cls = document.body.getAttribute('class') ? document.body.getAttribute('class').replace('loading', '') : '';
            document.body.setAttribute('class', cls + ' loading');
        }, C.timeouts.API_LOADING);
    }


    __requestFinished() {
        if(this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
        }

        let cls = document.body.getAttribute('class') ? document.body.getAttribute('class').replace('loading', '') : '';
        document.body.setAttribute('class', cls);
    }

}
