import React from 'react';
import Form from './form';
import UserInput from './userInput';
import {_} from '../locales/gettext';


export default class UserCheck extends Form {

    static defaultProps = {
        submitText: _('Continue'),
        title: _('Call Your TeamLeader'),
        text: _('Fill TeamLeader Credentials'),
    };

    render() {
        let inputs = <div>
            <UserInput
                ref="user"
                check={this.state.submitCheck}
                onScanSubmit={() => this.submit()}
            />
        </div>;

        return this.getForm(inputs);
    }

}
