import React from 'react';
import Page from './page';
import TestScan from '../forms/testScan';
import FaIcon from '../components/faIcon';
import {_} from '../locales/gettext';
import Tooltip from 'react-tooltip-lite';


export default class TestScanPage extends Page {

    componentDidMount() {
        this.setState({codes: []});
    }

    handleScan(values) {
        if(values.code) {
            let codes = this.state.codes;
            codes.push(values.code);
            this.setState({codes: codes});
            this.refs.form.refs.code.setValue('');

        }
    }


    handleClear() {
        this.setState({codes: []});
    }


    getContent(state) {
        let items = [];
        if(this.state.codes) {
            const codes = JSON.parse(JSON.stringify(this.state.codes));
            codes.reverse().forEach((item, key) => {
                if(key < 10) {
                    items.push(<div key={'item_' + key} className="formInput">
                        <textarea value={item} />
                    </div>);
                }
            });
        }
        if(!items.length) {
            items.push(<i key={'notFound'}>{_('No records found')}</i>);
        }

        return <div className="center testScan">
            <TestScan ref="form" onSubmit={(values) => this.handleScan(values)} />
            <h4 style={{marginTop: '3em'}}>
                {_('Scanned items')}
                {
                    this.state.codes && this.state.codes.length > 0 &&
                    <div onClick={() => this.handleClear()} className="delete">
                        <Tooltip content={_('Delete items')}>
                            <FaIcon icon="trash-alt"/>
                        </Tooltip>
                    </div>
                }
            </h4>
            {items}
        </div>;
    }

}
