import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {isAuthenticated, refreshToken, logout} from '../utils/auth';
import * as C from '../utils/constants';
import * as worksheetActions from '../redux/modules/worksheet';
import * as batchActions from '../redux/modules/batch';
import * as workActions from '../redux/modules/work';
import * as qualityAssuranceActions from '../redux/modules/qualityAssurance';
import * as unfinishedWorksheetsActions from '../redux/modules/unfinishedWorksheets';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import {_} from '../locales/gettext';

export default connect(
    (state, props) => {
        return {
            work: state.work,
        }
    },
    (dispatch) => {
        return {
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
        }
    }
)(
    class Authorized extends React.Component {

        static propTypes = {
            redirectPath: PropTypes.string,
        };

        static defaultProps = {
            redirectPath: C.routes.LOGIN,
        };

        constructor(props) {
            super(props);

            this.authChecker = null;
            this.state = {
                logout: false,
            };
        }

        componentDidMount() {
            if(isAuthenticated()) {
                refreshToken().catch(() => {
                    this.autoLogout();
                });
            }
        }

        componentWillUnmount() {
            if(this.authChecker) {
                clearInterval(this.authChecker);
            }
        }

        autoLogout() {
            this.setState({logout: true}, () => {
                alert(_('Your session has expired. Please login again.'));
            });
        }

        render() {
            if(!isAuthenticated() || this.state.logout) {
                this.props.worksheetActions.clear();
                this.props.unfinishedWorksheetsActions.clear();
                this.props.batchActions.clear();
                this.props.workActions.clear();
                this.props.qualityAssuranceActions.clear();
                logout();
                return <Redirect to={this.props.redirectPath} />;
            } else {
                clearInterval(this.authChecker);
                this.authChecker = setInterval(() => {
                    if(!isAuthenticated()) {
                        this.autoLogout();
                    }
                    refreshToken().catch(() => {
                        this.autoLogout();
                    });
                }, C.timeouts.AUTH_CHECK);
            }

            return this.props.children;
        }

    }
)
