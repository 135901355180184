import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Offline} from 'react-detect-offline';
import * as C from '../utils/constants';
import Authorized from '../components/authorized';
import LoginButton from '../components/loginButton';
import CurrentWork from '../components/currentWork';
import CurrentQualityAssurance from '../components/currentQualityAssurance';
import FlashMessage from '../components/flashMessage';
import LangSelect from '../components/langSelect';
import {_} from '../locales/gettext';
import Clock from 'react-live-clock';
import {isAuthenticated, getAuthStruct} from '../utils/auth';
import moment from 'moment';

export default class Page extends React.Component {

    static propTypes = {
        authRequired: PropTypes.bool,
        useEnvelope: PropTypes.bool,
    };

    static defaultProps = {
        authRequired: true,
        useEnvelope: true,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {
        let page = <div>
            {
                this.props.useEnvelope &&
                <div>
                    <div id="heading">
                        <Link id="logo" to={C.routes.LOGIN}>
                            <img src="/img/logo.png" alt={_('Q&L Industrial Services')} />
                        </Link>
                        <LoginButton
                            onLogout={() => {
                                this.props.history.replace(C.routes.LOGIN);
                            }}
                        />
                        <LangSelect />
                    </div>
                    <CurrentWork
                        onFinish={() => {
                            this.props.history.replace(C.routes.HOMEPAGE);
                        }}
                    />
                    <CurrentQualityAssurance
                        onFinish={() => {
                            this.props.history.replace(C.routes.HOMEPAGE);
                        }}
                    />
                    <div id="content">
                        {this.props.flashMessage}
                        {this.getContent(this.props.location.state)}
                    </div>
                    <div id="footer">
                        {
                            process.env.REACT_APP_DEVELOPMENT &&
                            <div className="environmentMessage">{_('Development environment')}</div>
                        }
                        <Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Prague'} />
                        &nbsp;| {_('APP v')}{C.VERSION}
                        &nbsp;| {_('API v')}{C.API_VERSION}
                        {
                            isAuthenticated() &&
                            <span>
                                &nbsp;| {_('Session expire')}:
                                &nbsp;{moment(getAuthStruct().refreshExpire * 1000).format('DD.MM.YYYY, HH:mm')}
                            </span>
                        }
                    </div>
                </div>
            }
            {!this.props.useEnvelope && this.getContent(this.props.location.state)}
        </div>;

        if(this.props.authRequired) {
            page = <Authorized redirectPath={this.props.authRequired ? C.routes.LOGIN : null}>
                {page}
            </Authorized>;
        }

        // 8.9.2022 Vypol som polling, pretoze uz niekolko dni hapruje pripojenie na httpbin.org a dnes kompletne spadlo. neviem, ci tu mam davat nejaky iny server, nas, ktory by sme zatazovali.
        page = <div>
            {page}
            <Offline polling={false}>
                <FlashMessage fullScreen={true} type={C.flashMessages.ERROR}>
                    <h3>{_('Application is offline')}</h3>
                    {_('Trying to reconnect, please wait')}
                    <div className="spinner error">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </FlashMessage>
            </Offline>
        </div>;

        return page;
    }

}
