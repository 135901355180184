import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UpdateMessage from './components/updateMessage';
import * as serviceWorker from './serviceWorker';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import reducers from './redux/reducers/app';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/browser';
import * as C from './utils/constants';
import {getId} from './utils/auth';

if(process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
    Sentry.configureScope(scope => {
        scope.setTag('api', C.API_VERSION);
        scope.setTag('userId', getId());
    });
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['flashMessage']
}

let persistedReducer = persistReducer(persistConfig, reducers)
let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
let persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
let sConfig = {};
sConfig.onUpdate = function(registration) {
    store.dispatch({ type: '@flashmessage/SET', message: <UpdateMessage registration={registration} />});
}
sConfig.onSuccess = function(registration) {
    //registration.update();
    //registration.unregister();
}
serviceWorker.register(sConfig);
