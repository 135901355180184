import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from '../components/flashMessage';
import * as C from '../utils/constants';
import UserCheck from '../forms/userCheck';
import ApiClient from '../utils/apiClient';
import {_} from '../locales/gettext';


export default class ErrorMessages extends React.Component {

    static propTypes = {
        errorCode: PropTypes.any,
        errorMessage: PropTypes.any,
        alert: PropTypes.any,
        onSubmit: PropTypes.func,
        onInvalidSubmit: PropTypes.func,
        fullScreen: PropTypes.bool,
    };


    static defaultProps = {
        fullScreen: true,
    };


    render() {

        let content = null;

        switch(this.props.errorCode) {

            case C.errors.CHECK_TL:
                const api = new ApiClient(this.props);
                content = <FlashMessage fullScreen={true} type={C.flashMessages.ERROR} closeButton={true}>
                    <UserCheck
                        title={this.props.errorMessage}
                        alert={this.props.alert ? <div className="flashMessage alert"><div className="inner">{this.props.alert}</div></div> : null}
                        onSubmit={async (values) => {
                            try {
                                const response = await api.authenticate(values.user);
                                this.props.onSubmit(response.data);
                            } catch(error) {
                                this.props.onInvalidSubmit(error);
                            }
                        }}
                    />
                </FlashMessage>;
                break;

            case C.errors.BAD_LOGIN:
            case C.errors.INSTRUCTION_NOT_FOUND:
            case C.errors.INSTRUCTION_NOT_APPROVED:
            case C.errors.JOB_CLOSED:
            case C.errors.PART_NUMBER_NOT_FOUND:
                content = <FlashMessage type={C.flashMessages.ERROR} closeButton={true}>
                    {this.props.errorMessage}
                </FlashMessage>;
                break;

            default:
                content = <FlashMessage fullScreen={this.props.fullScreen} type={C.flashMessages.ERROR} closeButton={true} okButton={this.props.fullScreen}>
                    {this.props.fullScreen && <h3>{_('Error')}</h3>}
                    {
                        this.props.errorMessage ?
                            this.props.errorMessage :
                            <div>
                                <h3>{_('Unknown Error')}</h3>
                                {_('Please Contact Admin')}
                            </div>
                    }
                </FlashMessage>;
                break;
        }

        return content;
    }
}
