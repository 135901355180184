import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import WorkPage from './pages/work';
import TestScanPage from './pages/testScan';
import UnfinishedPage from './pages/unfinished';
import QualityAssurancePage from './pages/qualityAssurance';
import * as C from './utils/constants';
import {_} from './locales/gettext';
import FlashMessage from './components/flashMessage';
import FaIcon from './components/faIcon';
import {connect} from "react-redux";
import {bindActionCreators} from "./redux/bindActionCreators";
import * as worksheetActions from "./redux/modules/worksheet";
import * as batchActions from "./redux/modules/batch";
import * as workActions from "./redux/modules/work";
import * as qualityAssuranceActions from "./redux/modules/qualityAssurance";
import * as unfinishedWorksheetsActions from "./redux/modules/unfinishedWorksheets";


import './css/main.scss';
import './css/tooltip.scss';
import './css/loader.scss';
import './css/responsive.scss';

export default connect(
    (state, props) => {
        return {
            flashMessage: state.flashMessage,
        }
    },
    (dispatch) => {
        return {
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
        }
    }
)(
    class App extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                error: false,
            }
        }


        componentDidCatch(error, errorInfo) {
            this.setState({error: true});
        }


        handleReload(deleteState = false) {
            if(deleteState && window.confirm(_('Delete State, Are you sure?'))) {
                this.props.worksheetActions.clear();
                this.props.unfinishedWorksheetsActions.clear();
                this.props.batchActions.clear();
                this.props.workActions.clear();
                this.props.qualityAssuranceActions.clear();
            }

            window.location.reload();
        }


        render() {
            if('ontouchstart' in document.documentElement) {
                document.body.classList.add('touchDevice');
            }

            if(this.state.error) {
                return <FlashMessage
                    type={C.flashMessages.ERROR}
                    closeButton={false}
                    okButton={false}
                    fullScreen={true}
                >
                    <h3>{_('Unknown Application Error')}</h3>
                    <div style={{marginTop: '30px'}}>
                        <button onClick={() => { this.handleReload(false); }}><FaIcon icon={'redo'} /> {_('Try Reload Application')}</button>
                    </div>
                    <div style={{marginTop: '30px'}}>
                        <button onClick={() => { this.handleReload(true); }}><FaIcon icon={'trash-alt'} /> {_('Delete State & Reload Application')}</button>
                    </div>
                </FlashMessage>;
            }

            return <BrowserRouter>
                <Switch>
                    <Route path={C.routes.HOMEPAGE} exact component={HomePage} />
                    <Route path={C.routes.LOGIN} exact component={LoginPage} />
                    <Route path={C.routes.WORK} exact component={WorkPage} />
                    <Route path={C.routes.TEST_SCAN} exact component={TestScanPage} />
                    <Route path={C.routes.UNFINISHED} exact component={UnfinishedPage} />
                    <Route path={C.routes.QUALITY_ASSURANCE} exact component={QualityAssurancePage} />
                    <Route component={() => { return <Redirect to={C.routes.HOMEPAGE} /> }} />
                </Switch>
            </BrowserRouter>;
        }

})
