import React from 'react';
import PropTypes from 'prop-types';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/free-solid-svg-icons';


export default class FaIcon extends React.Component {

    static propTypes = {
        icon: PropTypes.string.isRequired,
    };


    render() {
        let parts = this.props.icon.split('-');
        let iconName = 'fa';
        parts.forEach((part) => {
            iconName += part.charAt(0).toUpperCase() + part.slice(1);
        });
        if(!library.definitions || !library.definitions.fas || !library.definitions.fas[this.props.icon]) {
            library.add(Icon[iconName]);
        }
        return <FontAwesomeIcon icon={this.props.icon} className='fa-fw' />;
    }

}
