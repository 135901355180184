import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {isAuthenticated, logout, getLogin} from '../utils/auth';
import FaIcon from './faIcon';
import Tooltip from 'react-tooltip-lite';
import * as worksheetActions from '../redux/modules/worksheet';
import * as batchActions from '../redux/modules/batch';
import * as workActions from '../redux/modules/work';
import * as qualityAssuranceActions from '../redux/modules/qualityAssurance';
import * as unfinishedWorksheetsActions from '../redux/modules/unfinishedWorksheets';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import {_} from '../locales/gettext';

export default connect(
    (state, props) => {
        return {
            work: state.work,
        }
    },
    (dispatch) => {
        return {
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
            qualityAssuranceActions: bindActionCreators(qualityAssuranceActions, dispatch),
        }
    }
)(
    class LoginButton extends React.Component {

        static propTypes = {
            onLogout: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
            };
        }


        logout() {
            if(window.confirm(_('Logout, are you sure?'))) {
                this.props.worksheetActions.clear();
                this.props.workActions.clear();
                this.props.batchActions.clear();
                this.props.unfinishedWorksheetsActions.clear();
                this.props.qualityAssuranceActions.clear();
                this.props.onLogout();
                logout();
            }
        }


        render() {
            const logged = isAuthenticated();

            return <div id="loginButton">
                {
                    logged &&
                    <span>
                        <Tooltip content={_('Logout')} className="target">
                            <span onClick={() => this.logout()}>
                                <FaIcon icon="power-off" />
                            </span>
                        </Tooltip> {getLogin()}
                    </span>
                }
            </div>;
        }

    }
)
