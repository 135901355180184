import moment from 'moment';
import ApiClient from './apiClient';
import * as C from './constants';

const storage = localStorage;
const api = new ApiClient();

export function isAuthenticated() {

    const auth = getAuthStruct();
    if(
        !auth ||
        !auth.token ||
        !auth.expire ||
        moment().unix() > auth.expire
    ) {
        return false;
    }

    return true;
}


export async function authenticate(username = null, password = null, token = null) {
    const params = {username: username, password: password, token: token};
    return new Promise(async (accept, reject) => {
        try {
            let response = await api.authenticate(params);

            const token = response.data ? response.data.token : null;
            const tokenExpiry = response.meta && response.meta.token && response.meta.token.expiry ? response.meta.token.expiry.unix : null
            const userData = response.data ? response.data.identity : null
            setAuthStruct(token, tokenExpiry, userData);

            accept(getAuthStruct());
        } catch (error) {
            reject(error);
        }
    });
}

// Poznamka: tato funkcia bezi kazdu minutu (C.timeouts.AUTH_CHECK),
//           ale api request robi az v momente,
//           ked je "expire" tokenu 15 minut pred vyprsanim platnosti (C.timeouts.REFRESH_TOKEN)
export async function refreshToken(force = false) {
    return new Promise(async (accept, reject) => {
        if(force || (getAuthStruct() && getAuthStruct().expire < moment().unix() + C.timeouts.REFRESH_TOKEN)) {
            try {
                const response = await api.callWholeResponse('POST', 'session/refresh', {token: getAuthStruct().token});

                const token = response.data ? response.data.token : null;
                const tokenExpiry = response.meta && response.meta.token && response.meta.token.expiry ? response.meta.token.expiry.unix : null
                const userData = response.data ? response.data.identity : null
                setAuthStruct(token, tokenExpiry, userData);

                accept(getAuthStruct());
            } catch(error) {
                reject(error);
            }
        } else {
            accept();
        }
    });
}


export function getLogin() {
    const authStruct = getAuthStruct().data;
    if(authStruct) {
        return authStruct.firstname || authStruct.lastname ? (authStruct.firstname + ' ' + authStruct.lastname).trim() : authStruct.login;
    } else {
        return null;
    }
}


export function getId() {
    return getAuthStruct() && getAuthStruct().data ? getAuthStruct().data.id : null;
}


export function getRole() {
    return getAuthStruct() && getAuthStruct().data ? getAuthStruct().data.role : null;
}


export function logout() {
    storage.removeItem('auth');
}


export function getAuthStruct() {
    const str = storage.getItem('auth');
    return JSON.parse(str);
}


function setAuthStruct(token, expire, data = null) {
    const prevStruct = getAuthStruct();

    if(!data && prevStruct && prevStruct.data) {
        data = prevStruct.data;
    }

    // TODO nastavit na nejaku hodnotu.. pokial tomu spravne rozumiem, tento parameter je len informacny (vizualny) a nikam sa neposiela
    const sessionExpiry = expire + 60*60 // *24*7 // na serveri bude nastavene, ze sa session da "slajdovat" po dobu 1 tyzdna, ale pre aplikaciu to mozeme nastavit len napriklad na hodinu

    const str = JSON.stringify({
        token: token,
        expire: expire,
        refreshExpire: sessionExpiry,
        data: data,
    });

    storage.setItem('auth', str);
}
