import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from '../redux/bindActionCreators';
import classNames from 'classnames';
import FaIcon from './faIcon';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import * as flashMessageActions from '../redux/modules/flashMessage';

export default connect(
    (state, props) => {
        return {
            flashMessage: state.flashMessage,
        }
    },
    (dispatch) => {
        return {
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class FlashMessage extends React.Component {

        static propTypes = {
            fullScreen: PropTypes.bool,
            closeButton: PropTypes.bool,
            okButton: PropTypes.bool,
            okButtonTitle: PropTypes.string,
            type: PropTypes.number,
            zIndex: PropTypes.number,
            onClose: PropTypes.func,
            autoHide: PropTypes.bool,
        };


        static defaultProps = {
            fullScreen: false,
            closeButton: false,
            okButton: false,
            okButtonTitle: _('Ok'),
            type: C.flashMessages.INFO,
            zIndex: 999,
            autoHide: false,
        }


        handleClose() {
            if(this.props.onClose) {
                this.props.onClose();
            } else {
                this.props.flashMessageActions.clear();
            }
        }


        render() {

            const cls = classNames('flashMessage', {
                'fullScreen': this.props.fullScreen,
                'info': this.props.type === C.flashMessages.INFO,
                'alert': this.props.type === C.flashMessages.ALERT,
                'error': this.props.type === C.flashMessages.ERROR,
                'common': this.props.type === C.flashMessages.COMMON,
                'hideMe': this.props.autoHide,
            });

            return <div className={cls} style={{zIndex: this.props.zIndex}}>
                <table style={{width: '100%', height: '100%'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign: 'middle'}}>
                                <div className="inner">
                                    {
                                        this.props.closeButton &&
                                        <span className="close scanHide" onClick={() => { this.handleClose(); }}><FaIcon icon="times" /></span>
                                    }
                                    {this.props.children}
                                    {
                                        this.props.okButton &&
                                        <div style={{marginTop: '2em'}} className="scanHide">
                                            <button onClick={() => { this.handleClose(); }}>{this.props.okButtonTitle}</button>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>;
        }

    }
)
