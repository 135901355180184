import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FlashMessage from './flashMessage';
import FaIcon from './faIcon';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import Input from '../forms/input';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as batchActions from '../redux/modules/batch';
import * as flashMessageActions from '../redux/modules/flashMessage';
import ErrorMessages from './errorMessages';
import ApiClient from '../utils/apiClient';
import BatchEntity from "../utils/batch";
import ComponentHelpers from '../utils/componentHelpers';

export default connect(
    (state, props) => {
        return {
            batch: state.batch,
            worksheet: state.worksheet,
            work: state.work,
        }
    },
    (dispatch) => {
        return {
            batchActions: bindActionCreators(batchActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
        }
    }
)(
    class BatchCounts extends React.Component {

        static propTypes = {
            edit: PropTypes.bool,
        };


        static defaultProps = {
            edit: false,
        };


        constructor(props) {
            super(props);

            this.api = new ApiClient(this.props);
            this.componentHelpers = new ComponentHelpers(this, this.api);

            this.state = {
                errors: [],
            }
        }


        async handleSave() {
            let changed = false;
            const batch = new BatchEntity(this.props.batch);

            const amount = parseInt(this.refs.amount.getValue());
            if(amount != this.props.batch.amount) {
                changed = true;
                batch.setAmount(amount);
            }

            const checked = parseInt(this.refs.checked.getValue());
            if(checked != this.props.batch.checked) {
                changed = true;
                batch.setChecked(checked);
            }

            this.props.batch.defects.forEach((defect) => {
                const nok = parseInt(this.refs[defect.id + '_nok'].getValue());
                const reworked = parseInt(this.refs[defect.id + '_reworked'].getValue());
                if(nok != defect.nok) {
                    changed = true;
                    batch.setNOK(defect.id, nok);
                }
                if(reworked != defect.reworked) {
                    changed = true;
                    batch.setReworked(defect.id, reworked);
                }
            });

            const errors = batch.validate();
            this.setState({errors: errors});

            if(!errors.length) {
                const affectedDefects = batch.getAffectedDefects();
                let securityJobs = [];
                this.props.work.jobs.forEach((job) => {
                    if(job.security) {
                        job.defects.forEach((defect) => {
                            if(affectedDefects.indexOf(defect.id) >= 0 && securityJobs.indexOf(job.code) < 0) {
                                securityJobs.push(job.code);
                            }
                        });
                    }
                });

                if(securityJobs.length) {
                    this.componentHelpers.setErrorMessage(
                        {code: C.errors.CHECK_TL, message: _('Security defect found, inform Teamleader')},
                        async (result) => {
                            await this.componentHelpers.checkTL(result, securityJobs, async () => {
                                try {
                                    const state = batch.populateNewState().getState();
                                    await this.api.patch(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes/' + this.props.batch.id, state);
                                    this.props.batchActions.set(state);
                                    this.props.flashMessageActions.set(<FlashMessage fullScreen={false} type={C.flashMessages.INFO} closeButton={true}>
                                        {_('Batch edit success')}
                                    </FlashMessage>);
                                } catch (error) {
                                    this.componentHelpers.setErrorMessage(error);
                                }
                            });
                        }
                    );
                } else {
                    try {
                        const state = batch.populateNewState().getState();
                        await this.api.patch(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes/' + this.props.batch.id, state);
                        this.props.batchActions.set(state);
                        this.props.flashMessageActions.set(<FlashMessage fullScreen={false} type={C.flashMessages.INFO} closeButton={true}>
                            {_('Batch edit success')}
                        </FlashMessage>);
                    } catch (error) {
                        this.props.flashMessageActions.set(<ErrorMessages fullScreen={false} errorCode={error.code} errorMessage={error.message} />);
                    }
                }


            }
        }


        getDefectRow(defect, job, nok, reworked, errors) {
            return <tr key={defect.id}>
                <td>
                    <b>{defect.title}</b><br />
                    <div className="jobTitle">{job.code} ({job.title})</div>
                    {errors}
                </td>
                <td>
                    <Input
                        ref={defect.id + '_nok'}
                        defaultValue={nok}
                        type="number"
                        scan={false}
                        disabled={!this.props.edit}
                    />
                </td>
                <td>
                    <Input
                        ref={defect.id + '_reworked'}
                        defaultValue={reworked}
                        type="number"
                        scan={false}
                        disabled={!this.props.edit || defect.reworkable != 1}
                    />
                </td>
            </tr>
        }


        render() {
            let rows = [];
            let amountError = [];
            let checkedError = [];
            let defectErrors = {};

            this.state.errors.forEach((error, key) => {
                if(error.field == 'amount') {
                    amountError.push(<div className="batchError" key={key}>{error.message}</div>);
                } else if(error.field == 'checked') {
                    checkedError.push(<div className="batchError" key={key}>{error.message}</div>);
                } else if(error.defect) {
                    if(!defectErrors[error.defect]) {
                        defectErrors[error.defect] = [];
                    }
                    defectErrors[error.defect].push(<div className="batchError" key={key}>{error.message}</div>);
                }
            });

            this.props.work.jobs.forEach((job) => {
                job.defects.forEach((defect) => {
                    let nok = 0;
                    let reworked = 0;
                    this.props.batch.defects.forEach((batchDefect) => {
                        if(defect.id == batchDefect.id) {
                            nok = batchDefect.nok;
                            reworked = batchDefect.reworked;
                        }
                    });
                    rows.push(this.getDefectRow(defect, job, nok, reworked, defectErrors[defect.id]));
                });
            });

            return <FlashMessage fullScreen={true} closeButton={true} okButton={false} type={C.flashMessages.COMMON}>
                <h3>{_('Edit Batch')}</h3>
                <table className="defectsTable" border="1" cellPadding="3" cellSpacing="0">
                    <tbody>
                        <tr key="total" className="headRow">
                            <th>
                                {_('Total')}
                                {amountError}
                            </th>
                            <td colSpan={2}>
                                <Input
                                    ref="amount"
                                    defaultValue={this.props.batch.amount}
                                    type="number"
                                    scan={false}
                                    disabled={!this.props.edit}
                                />
                            </td>
                        </tr>
                        <tr key="checked" className="headRow">
                            <th>
                                {_('Checked')}
                                {checkedError}
                            </th>
                            <td colSpan={2}>
                                <Input
                                    ref="checked"
                                    defaultValue={this.props.batch.checked}
                                    type="number"
                                    scan={false}
                                    disabled={!this.props.edit}
                                />
                            </td>
                        </tr>
                        <tr key="defectHeading" className="headRow">
                            <th>{_('Defect')}</th>
                            <th>{_('NOK')}</th>
                            <th>{_('Rework')}</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
                <div style={{marginTop: '30px'}}>
                    {
                        this.props.edit &&
                        <div>
                            <button onClick={() => { this.handleSave(); }}><FaIcon icon="save" /> {_('Save & Close')}</button>
                        </div>
                    }
                    {
                        !this.props.edit &&
                        <div>
                            <button onClick={() => { this.props.flashMessageActions.clear(); }}><FaIcon icon="times" /> {_('Close')}</button>
                        </div>
                    }
                </div>
            </FlashMessage>;
        }

    }
)
