import React from 'react';
import {connect} from 'react-redux';
import {sprintf} from 'sprintf-js';
import {bindActionCreators} from '../redux/bindActionCreators';
import * as worksheetActions from '../redux/modules/worksheet';
import * as workActions from '../redux/modules/work';
import * as batchActions from '../redux/modules/batch';
import * as flashMessageActions from '../redux/modules/flashMessage';
import * as unfinishedWorksheetsActions from '../redux/modules/unfinishedWorksheets';
import FlashMessage from '../components/flashMessage';
import * as C from '../utils/constants';
import ApiClient from '../utils/apiClient';
import Part from '../forms/part';
import Batch from '../forms/batch';
import FaIcon from './faIcon';
import {_} from '../locales/gettext';
import Notice from './notice';
import Highlights from './highlights';
import JobSelect from './jobSelect';
import BatchEntity from '../utils/batch';
import ComponentHelpers from '../utils/componentHelpers';
import classNames from 'classnames';
import Tooltip from 'react-tooltip-lite';
import Documents from "./documents";
import {addDefectPhotoSerie} from "../redux/modules/work";

export default connect(
    (state, props) => {
        return {
            work: state.work,
            worksheet: state.worksheet,
            batch: state.batch,
            unfinishedWorksheets: state.unfinishedWorksheets,
        }
    },
    (dispatch) => {
        return {
            worksheetActions: bindActionCreators(worksheetActions, dispatch),
            batchActions: bindActionCreators(batchActions, dispatch),
            workActions: bindActionCreators(workActions, dispatch),
            flashMessageActions: bindActionCreators(flashMessageActions, dispatch),
            unfinishedWorksheetsActions: bindActionCreators(unfinishedWorksheetsActions, dispatch),
        }
    }
)(
    class Work extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                workitem: 'default',
                defectNotice: null,
                pending: false,
                nok: {},
                nokPhoto: null,
            };

            this.api = new ApiClient(this.props);
            this.componentHelpers = new ComponentHelpers(this, this.api);
        }


        componentDidMount() {
            if(this.props.defaultState && this.props.defaultState.unfinished) {
                const worksheet = this.props.defaultState.unfinished;
                this.startJobs(worksheet.jobs, worksheet.part_number, worksheet.partTitle, worksheet.id);
                this.props.unfinishedWorksheetsActions.remove(worksheet.id);
                if(Array.isArray(worksheet.boxes)) {
                    worksheet.boxes.forEach((box) => {
                        if(box.closed) {
                            this.props.workActions.addBatch({...box, batchNumber: box.batch_number});
                        } else {
                            const batch = new BatchEntity();
                            batch.initCurrentState(box.id, box.batch_number, null, box.amount, box.defects, box.checked, box.comment);
                            this.props.batchActions.set(batch.getState());
                        }
                    });
                }
            }
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
        }


        async handlePartNumber(values) {
            try {
                const response = await this.api.get(C.workGroup + '/refs/' + values.partNumber);
                if(response.length >= 1) {
                    this.props.flashMessageActions.set(<JobSelect onStart={(jobs, partNumber, partTitle) => this.startJobs(jobs, partNumber, partTitle)} data={response} partNumber={values.partNumber} />);
                } else {
                    this.setErrorMessage({code: C.errors.PART_NUMBER_NOT_FOUND, message: _('Part Number not found')});
                }
            } catch(error) {
                this.setErrorMessage(error);
            }
        }


        async startJobs(jobs, partNumber, partTitle, worksheetId = null, responses = []) {
            this.props.flashMessageActions.clear();
            if(jobs.length) {
                const jobCode = jobs.shift();
                const response = await this.api.get(C.workGroup + '/jobs/' + jobCode + '/operators/' + this.api.userId);
                if(!response.eligible) {
                    this.setErrorMessage(
                        {code: C.errors.CHECK_TL, message: sprintf(_('You are not trained for job %s, inform TeamLeader'), jobCode)},
                        async (result) => {
                            try {
                                await this.api.__call('post', C.workGroup + '/operators/' + this.api.userId + '/trainings/' + jobCode, {}, result.token);
                                const jobResponse = await this.api.get(C.workGroup + '/jobs/' + jobCode);
                                responses.push(jobResponse);
                                this.startJobs(jobs, partNumber, partTitle, worksheetId, responses);
                            } catch(error) {
                                this.setErrorMessage(error, null, false);
                            }
                        },
                    );
                } else {
                    const jobResponse = await this.api.get(C.workGroup + '/jobs/' + jobCode);
                    responses.push(jobResponse);
                    this.startJobs(jobs, partNumber, partTitle, worksheetId, responses);
                }
            } else {
                let notices = [];
                let jobs = [];

                try {
                    responses.forEach((response) => {
                        this.props.workActions.addJob(response);
                        jobs.push(response.id);
                        notices = [...notices, ...response.instruction.highlights];
                    });

                    if(notices.length) {
                        this.props.flashMessageActions.set(<Highlights
                            title={_('Job instructions has security notices')}
                            messages={notices}
                        />);
                    }

                    if(!worksheetId) {
                        const params = {
                            jobs: jobs,
                            part_number: partNumber,
                        }

                        const response = await this.api.post(C.workGroup + '/worksheets', params);
                        this.props.worksheetActions.set(partNumber, partTitle, response.id);
                    } else {
                        this.props.worksheetActions.set(partNumber, partTitle, worksheetId);
                    }
                } catch(error) {
                    this.setErrorMessage(error);
                }


                /*
                const worksheets = await this.api.get(C.workApi + '/jobs/' + data.job.code + '/operators/' + this.api.userId);
                let inProgress = null;
                worksheets.forEach((item) => {
                    if(item.part_number === partNumber) {
                        if(!inProgress || item.id > inProgress.id) {
                            inProgress = item;
                        }
                    }
                });

                if(inProgress) {
                    this.props.worksheetActions.set(inProgress.part_number, partTitle, inProgress.id);
                    let lastBox = null;
                    inProgress.boxes.forEach((box) => {
                        if(!lastBox || box.id > lastBox.id) {
                            lastBox = box;
                        }
                    });
                    if(lastBox && toInt(lastBox.checked) < toInt(lastBox.amount)) {
                        this.props.batchActions.set(lastBox.batch_number, lastBox.id, lastBox.name, toInt(lastBox.amount), toInt(lastBox.checked), toInt(lastBox.nok), toInt(lastBox.reworked), lastBox.comment);
                    }
                } else {
                    const params = {
                        jobs: jobs,
                        part_number: partNumber,
                    }

                    try {
                        const response = await this.api.post(C.workApi + '/worksheets', params);
                        this.props.worksheetActions.set(partNumber, partTitle, response.id);
                    } catch(error) {
                        this.setErrorMessage(error);
                    }
                }
                */

            }

            return;
        }


        async handleBatch(values, checkGuaranteed = true) {
            const params = {
                amount: values.quantity,
                batch_number: values.batch,
                box_number: values.name,
            }

            let defects = [];
            let guaranteed = false;
            this.props.work.jobs.forEach((job) => {
                job.batches.forEach((item) => {
                    if(item.batch_number === values.batch) {
                        guaranteed = job.code;
                    }
                });

                job.defects.forEach((defect) => {
                    defects.push({
                        id: defect.id,
                        nok: 0,
                        reworked: 0,
                        comment: '',
                        documents: [],
                    });
                });
            });

            if(guaranteed && checkGuaranteed) {
                const error = {
                    message: sprintf(_('Batch %s is guaranteed, inform TeamLeader'), values.batch),
                    code: C.errors.CHECK_TL,
                }

                this.setErrorMessage(
                    error,
                    async (result) => {
                        await this.componentHelpers.checkTL(result, [guaranteed], async () => {
                            await this.handleBatch(values, false);
                            this.props.flashMessageActions.set(<FlashMessage fullScreen={false} type={C.flashMessages.INFO} closeButton={true}>
                                {_('Guaranteed batch was dispatched')}
                            </FlashMessage>);
                        });
                    }
                );
            } else {
                this.setState({pending: true});
                try {
                    const response = await this.api.post(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes', params);
                    const batch = new BatchEntity();
                    batch.initCurrentState(response.id, values.batch, values.name, values.quantity, defects);
                    this.props.batchActions.set(batch.getState());
                    this.setState({pending: false});
                } catch(error) {
                    this.setErrorMessage(error);
                }
            }
        }


        async handleCheck(count) {
            this.setState({pending: true});
            const batch = new BatchEntity(this.props.batch);
            batch.addCheck(count);
            if(this.checkBatch(batch)) {
                try {
                    const state = batch.populateNewState().getState();
                    await this.api.patch(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes/' + this.props.batch.id, state);
                    this.props.batchActions.set(state);
                    this.setState({pending: false});
                } catch(error) {
                    this.setErrorMessage(error, {}, false);
                }
            }
        }


        setErrorMessage(error, callback, fullScreen = true) {
            this.componentHelpers.setErrorMessage(error, callback, fullScreen);
            this.setState({pending: false});
        }


        handleNok(id, type) {
            let current = {};
            Object.keys(this.state.nok).map((key) => {
                if(key == id) {
                    current[id] = this.state.nok[id];
                }
            });

            if(!current[id]) {
                current[id] = {
                    defect: false,
                    rework: false,
                    all: false,
                    notice: '',
                    documents: [],
                };
            }

            if(type == 'nok') {
                current[id].defect = !current[id].defect;
                if(current[id].defect) {
                    current[id].all = false;
                }
            } else if(type == 'rework') {
                current[id].rework = !current[id].rework;
            } else if(type == 'all') {
                current[id].all = !current[id].all;
                if(current[id].all) {
                    current[id].defect = false;
                }
            }

            this.setState({nok: current});
        }


        handleAddDocument(defect, document) {
            this.state.nok[defect].documents.unshift(document);
            this.forceUpdate();
        }


        handleRemoveDocument(defect, index) {
            this.state.nok[defect].documents.splice(index, 1);
            this.forceUpdate();
        }


        checkBatch(batchEntity) {
            const errors = batchEntity.validate();
            if(errors.length) {
                this.setErrorMessage(_('Error in batch counts'), null, false);
                return false;
            } else {
                return true;
            }
        }


        async handleDefects() {
            const batch = new BatchEntity(this.props.batch);
            let isAll = false;

            Object.keys(this.state.nok).forEach((key) => {
                if(this.state.nok[key].all) {
                    let item = this.state.nok[key];
                    batch.setNOK(key, this.props.batch.amount, item.notice, item.documents);
                    batch.setChecked(this.props.batch.amount);
                    isAll = true;
                }
            });

            if(!isAll) {
                Object.keys(this.state.nok).forEach((key) => {
                    let item = this.state.nok[key];
                    batch.addDefect(key, item.rework, item.notice, item.documents, item.defect);
                });
            }

            if(this.checkBatch(batch)) {
                const affectedDefects = batch.getAffectedDefects();
                let securityJobs = [];
                let photoSeriesData = [];
                this.props.work.jobs.forEach((job) => {
                    if(job.security) {
                        job.defects.forEach((defect) => {
                            if(affectedDefects.indexOf(defect.id) >= 0 && securityJobs.indexOf(job.code) < 0) {
                                securityJobs.push(job.code);
                            }
                        });
                    }
                    job.defects.forEach((defect) => {
                        if(
                            this.state.nok[defect.id] &&
                            this.state.nok[defect.id].defect &&
                            this.state.nok[defect.id].documents &&
                            this.state.nok[defect.id].documents.length
                        ) {
                            let documents = [];
                            this.state.nok[defect.id].documents.forEach((item) => {
                                if(item.type == 'photo_serie') {
                                    this.props.workActions.addDefectPhotoSerie(defect.id);
                                    item.data.forEach((item2) => {
                                        // Webcam.getScreenshot ziska "data URI" vo formate `data:[<media type>][;charset=<character set>][;base64],<data>`
                                        // treba vyextrahovat <data>
                                        // @link https://github.com/ragingwind/data-uri-regex/blob/master/index.js regexp zdroj
                                        const dataRegex = new RegExp(/^(data:)([\w\/\+-]*)(;charset=[\w-]+|;base64){0,1},(.*)/gi);
                                        const base64contents = dataRegex.exec(item2.base64)[4]

                                        documents.push({
                                            type: parseInt(item2.serieType),
                                            file: {
                                                mime: 'image/jpeg',
                                                name: `image${item2.serieType}.jpg`,
                                                contents: base64contents,
                                            }
                                        })
                                    });
                                }
                            });
                            if(documents.length) {
                                photoSeriesData.push({
                                    job: parseInt(job.id),
                                    defect: parseInt(defect.id),
                                    date: (new Date()).toISOString().substring(0, 10),
                                    part_number: this.props.worksheet.partNumber,
                                    // batch_number: parseInt(this.props.batch.id), // TODO toto vyzera ako bug, potrebujeme batch_number, nie poradove cislo krabice
                                    batch_number: this.props.batch.batchNumber || null,
                                    worksheet: parseInt(this.props.worksheet.id),
                                    documents: documents,
                                });
                            }
                        }
                    });
                });

                const subroutine1 = async () => {
                    try {
                        let state = batch.populateNewState().getState();
                        this.props.batchActions.set(state);
                        state = JSON.parse(JSON.stringify(state));
                        state.defects.forEach((item) => {
                            item.documents = [];
                        });
                        await this.api.patch(C.workGroup + '/worksheets/' + this.props.worksheet.id + '/boxes/' + this.props.batch.id, state);
                        photoSeriesData.forEach((item) => {
                            this.api.post(C.workGroup + '/photodox', item)
                        });
                        this.props.flashMessageActions.set(<FlashMessage fullScreen={false} type={C.flashMessages.INFO} closeButton={true} okButton={false}>
                            {_('Items Saved')}
                        </FlashMessage>);
                    } catch (error) {
                        this.setErrorMessage(error);
                    } finally {
                        this.setState({nok: {}, workitem: 'default'});
                    }
                }

                if(securityJobs.length) {
                    this.setErrorMessage(
                        {code: C.errors.CHECK_TL, message: _('Security defect found, inform Teamleader')},
                        async (result) => {
                            await this.componentHelpers.checkTL(result, securityJobs, async () => {
                                await subroutine1()
                            });
                        }
                    );
                } else {
                    await subroutine1()
                }
            }
        }


        handleNotice(id, notice) {
            let current = this.state.nok;
            current[id].notice = notice;
            this.setState({nok: current});
        }


        render() {
            let content;

            if(!this.props.work) {
                content = <Part onSubmit={(values) => this.handlePartNumber(values)} />;
            } else  {
                const multiJob = this.props.work.jobs.length > 1;

                if(this.props.worksheet && this.props.batch) {
                    switch(this.state.workitem) {
                        case 'default':
                            let steps = [];
                            let noStep = true;
                            [1, 5, 10, 20, 50, 100, 500, 1000].forEach((item) => {
                                if(item <= (this.props.batch.amount - this.props.batch.checked)) {
                                    steps.push(<button disabled={this.state.pending} key={'step_' + item} onClick={() => this.handleCheck(item)}>+ {item}</button>);
                                    noStep = false;
                                }
                            });

                            if(noStep) {
                                steps.push(<span className="allChecked">{_('You checked already all items from batch')}</span>)
                            }

                            content = <div>
                                <div className="checking">
                                    <h4 style={{marginBottom: '1em'}}>{_('OK')}</h4>
                                    {steps}
                                </div>

                                <h4 style={{marginBottom: '1em'}}>{_('NOK')}</h4>
                                <div key="nok" style={{marginBottom: '2em'}}>
                                    <button disabled={this.state.pending || noStep} onClick={() => {this.setState({workitem: 'nok'})}}>
                                        {_('Defects & reworks')}
                                    </button>
                                </div>
                            </div>;
                            break;

                        case 'nok':
                            content = [];
                            let rows = [];
                            let documentsColumn = false;
                            let documentsMissing = 0;
                            const defaultPhotodoxVolume = 3; // default pocet serii fotiek
                            const reqPhotodoxVolume = (job) => job.worksheet_settings && typeof job.worksheet_settings.required_photodox_volume !== 'undefined' ? parseInt(job.worksheet_settings.required_photodox_volume) : defaultPhotodoxVolume
                            this.props.work.jobs.forEach((job) => {
                                const requiredPhotoSeries = reqPhotodoxVolume(job);
                                job.defects.forEach((defect) => {
                                    if(requiredPhotoSeries) {
                                        const requiredDocuments = Math.min(1, Math.max(0, requiredPhotoSeries - (this.props.work.defectPhotoSeries[defect.id] ? this.props.work.defectPhotoSeries[defect.id] : 0)));
                                        const nok = this.state.nok[defect.id];
                                        if(requiredDocuments) {
                                            documentsColumn = true;
                                        }
                                        if(nok && nok.defect && nok.documents.length < requiredDocuments) {
                                            documentsMissing += (requiredDocuments - this.state.nok[defect.id].documents.length);
                                        }
                                    }
                                })
                            });

                            this.props.work.jobs.forEach((job) => {
                                const requiredPhotoSeries = reqPhotodoxVolume(job);
                                job.defects.forEach((defect) => {
                                    const nok = this.state.nok[defect.id];
                                    const requiredDocuments = Math.min(1, Math.max(0, requiredPhotoSeries - (this.props.work.defectPhotoSeries[defect.id] ? this.props.work.defectPhotoSeries[defect.id] : 0)));

                                    rows.push(
                                        <tr key={defect.id}>
                                            <th>
                                                {defect.title}
                                                {
                                                    multiJob &&
                                                    <div className="jobTitle">{job.code} ({job.title})</div>
                                                }
                                            </th>
                                            <td style={{whiteSpace: 'nowrap'}}>
                                                <div style={{display: 'inline-block'}}>
                                                    <Tooltip content={_('Single defect')}>
                                                        <button className={classNames({smallButton: true, disabled: !nok || !nok.defect, pointer: true})} onClick={() => this.handleNok(defect.id, 'nok')}>
                                                            <FaIcon icon={'minus-circle'} />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                                {
                                                    !multiJob && !!job.worksheet_settings && !!job.worksheet_settings.allow_whole_box_defective &&
                                                    <div style={{display: 'inline-block', marginLeft: '0.5em'}}>
                                                        <Tooltip content={_('Whole box defective')}>
                                                            <button className={classNames({smallButton: true, disabled: !nok || !nok.all, pointer: true})} onClick={() => this.handleNok(defect.id, 'all')}>
                                                                <FaIcon icon={'hand-paper'} />
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </td>
                                            {
                                                documentsColumn &&
                                                <td className="requiredDocuments">
                                                    {
                                                        requiredDocuments && nok && nok.defect &&
                                                        <div>
                                                            <span className={classNames('count', {red: nok.documents.length < requiredDocuments})}>{nok.documents.length < requiredDocuments ? _('Required') + ' ' : ''}{nok.documents.length}/{requiredDocuments}</span>
                                                            <div style={{display: 'inline-block'}}>
                                                                <Documents
                                                                    documents={nok.documents}
                                                                    allowAdd={[C.documentType.PHOTO_SERIE]}
                                                                    allowRemove={true}
                                                                    onAdd={(document) => this.handleAddDocument(defect.id, document)}
                                                                    onRemove={(index) => this.handleRemoveDocument(defect.id, index)}
                                                                    photoSerie={[
                                                                        {
                                                                            type: 1,
                                                                            required: true,
                                                                            name: _('Defect'),
                                                                            description: _('Defect part photo'),
                                                                        },
                                                                        {
                                                                            type: 2,
                                                                            required: true,
                                                                            name: _('Label'),
                                                                            description: _('Label on part'),
                                                                        },
                                                                        {
                                                                            type: 3,
                                                                            required: true,
                                                                            name: _('Galia'),
                                                                            description: _('Label on box (galia)'),
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </td>
                                            }
                                            <td>
                                                {
                                                    parseInt(defect.reworkable) == C.reworkable.REWORKABLE &&
                                                    !!nok && nok.defect &&
                                                    <button className={classNames({smallButton: true, disabled: !nok || !nok.rework, pointer: true})} onClick={() => this.handleNok(defect.id, 'rework')}>
                                                        <FaIcon icon={'sync'} />
                                                    </button>
                                                }
                                                {
                                                    parseInt(defect.reworkable) === C.reworkable.NOT_REWORKABLE &&
                                                    <span className="empty">{_('N/A')}</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    !!nok && (nok.rework || nok.defect || nok.all) &&
                                                    <Notice
                                                        value={nok.notice}
                                                        title={_('NOK Comment')}
                                                        hideTitle={true}
                                                        buttonTitle={null}
                                                        buttonClass={classNames({disabled: !nok.notice, smallButton: true, pointer: true})}
                                                        onSave={(notice) => this.handleNotice(defect.id, notice)}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    );
                                });
                            });

                            if(!rows.length) {
                                rows.push(<tr><td colSpan={4}><span className="empty">{_('No NOKs available')}</span></td></tr>);
                            }

                            content.push(<table className="defectsTable" border="1" cellSpacing="0">
                                <tbody>
                                <tr className="headRow">
                                    <th>
                                        {_('Title')}
                                        {
                                            multiJob &&
                                            <i style={{fontWeight: 'normal'}}> ({_('Job')})</i>
                                        }
                                    </th>
                                    <th>{_('Defect')}</th>
                                    {documentsColumn && <th>{_('Photos')}</th>}
                                    <th>{_('Rework')}</th>
                                    <th>{_('Notice')}</th>
                                </tr>
                                {rows}
                                </tbody>
                            </table>);

                            content.push(<div className="bottomButtons">
                                {
                                    documentsMissing == 0 &&
                                    <button key="back" onClick={() => this.handleDefects()} className="smallButton">
                                        <FaIcon icon={'save'} /> {_('Save & Close')}
                                    </button>
                                }
                                {
                                    documentsMissing != 0 &&
                                    <Tooltip content={sprintf(_('Missing %s of required photos'), documentsMissing)} styles={{display: 'inline-block'}}>
                                        <button key="back" className="smallButton disabled">
                                            <FaIcon icon={'save'} /> {_('Save & Close')}
                                        </button>
                                    </Tooltip>
                                }
                                <button key="send" onClick={() => {this.setState({nok: {}, workitem: 'default'})}} className="smallButton" style={{marginLeft: '10px'}}>
                                    <FaIcon icon={'undo'} /> {_('Clear & Back')}
                                </button>
                            </div>);

                            break;
                        default:
                            content = [];
                    }
                } else {
                    const requiredBatch = this.props.work.requiredBatch;
                    let batchTitle = _('Scan Quantity');
                    if(requiredBatch) {
                        batchTitle = _('Scan Batch & Quantity');
                    }
                    content = <Batch
                        onSubmit={(values) => this.handleBatch(values)}
                        requiredBatch={requiredBatch}
                        requiredName={this.props.work.requiredBoxNumber}
                        title={batchTitle}
                        requiredPartNumber={this.props.worksheet ? this.props.worksheet.partNumber : ''}
                    />;
                }
            }

            return content;
        }
    }
)
