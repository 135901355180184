import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import {sprintf} from 'sprintf-js';
import Tooltip from "react-tooltip-lite";
import FaIcon from "./faIcon";
import moment from "moment";
import ComponentHelpers from "../utils/componentHelpers";
import Documents from "./documents";


export default class Inspections extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        inspections: PropTypes.array.isRequired,
        defects: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
    };


    constructor(props) {
        super(props);

        this.state = {
            detail: null,
        };

        this.componentHelpers = new ComponentHelpers(this, this.api);
    }


    getInspectionRow(inspection, key) {
        if(this.state.detail !== null && this.state.detail != key) {
            return;
        }


        const detailed = this.state.detail == key;

        return <tr>
            <td>
                {inspection.start && inspection.start.iso ? moment(inspection.start.iso).format('D.M.YYYY, HH:mm') : _('N/A')}
                &nbsp; {!!inspection.new && <i className="empty">({_('Current')})</i>}
            </td>
            <td>{inspection.end && inspection.end.iso ? moment(inspection.end.iso).format('D.M.YYYY, HH:mm') : _('N/A')}</td>
            <td>{inspection.operator.name}</td>
            <td>{inspection.results.length}</td>
            <td>
                {
                    detailed &&
                    <Tooltip content={_('Close Inspection Details')} className="target">
                        <button className="smallButton" onClick={() => this.setState({detail: null})}>
                            <FaIcon icon={'times'} />
                        </button>
                    </Tooltip>
                }
                {
                    !detailed &&
                    <Tooltip content={_('Show Inspection Details')} className="target">
                        <button className="smallButton" onClick={() => this.setState({detail: key})}>
                            <FaIcon icon={'eye'} />
                        </button>
                    </Tooltip>
                }
            </td>
        </tr>;
    }


    getInspectionDetails() {
        if(this.state.detail !== null) {
            const defects = this.props.inspections[this.state.detail].results;
            let rows = [];


            defects.forEach((defect) => {
                let defectTitle;

                this.props.defects.forEach((item) => {
                    if(item.defect == defect.id) {
                        defectTitle = item.title;
                    }
                });

                rows.push(<tr>
                    <td>{defectTitle ? defectTitle : _('N/A')}</td>
                    <td>{this.componentHelpers.getDefectStateTitle(defect.state)}</td>
                    <td>{defect.description}</td>
                    <td>
                        <Documents documents={defect.documents} />
                    </td>
                </tr>);
            });

            if(!rows.length) {
                rows.push(<tr key=""><td colSpan={4}><span className="empty">{_('No Checks Found')}</span></td></tr>);
            }

            return <div style={{marginTop: '1em'}}>
                <h4>{_('Checks for selected inspection')}</h4>
                <table className="jobsTable inspectionsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                    <tbody>
                    <tr key="heading" className="headRow">
                        <th>{_('Defect')}</th>
                        <th>{_('State')}</th>
                        <th>{_('Description')}</th>
                        <th>{_('Documents')}</th>
                    </tr>
                    {rows}
                    </tbody>
                </table>
            </div>;
        }

        return null;
    }


    render() {
        let rows = [];
        this.props.inspections.forEach((inspection, key) => {
            rows.push(this.getInspectionRow(inspection, key));
        });

        if(!rows.length) {
            rows.push(<tr key=""><td colSpan={5}><span className="empty">{_('No Inspections Found')}</span></td></tr>);
        }

        return <FlashMessage fullScreen={true} closeButton={true} okButton={true} type={C.flashMessages.COMMON} onClose={() => this.props.onClose()}>
            <h3>{sprintf(_('Inspections of subject %s'), this.props.data.title)}:</h3>
            <table className="jobsTable inspectionsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                <tbody>
                <tr key="heading" className="headRow">
                    <th>{_('Start')}</th>
                    <th>{_('End')}</th>
                    <th>{_('Operator')}</th>
                    <th>{_('Checks')}</th>
                    <th>{_('Detail')}</th>
                </tr>
                {rows}
                </tbody>
            </table>
            {this.getInspectionDetails()}
        </FlashMessage>;
    }

}
