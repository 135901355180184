import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import Input from '../forms/input';
import FaIcon from './faIcon';
import classNames from "classnames";

export default class JobSelect extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        onStart: PropTypes.func.isRequired,
        partNumber: PropTypes.string.isRequired,
    };


    constructor(props) {
        super(props);

        this.state = {
            error: false,
            selectedJobs: [],
        };
    }


    handleValue(str) {
        let value;
        let count = 0;
        if(str) {
            this.props.data.forEach((item) => {
                value = str.trim().toLowerCase();
                if(value.startsWith('V') || value.startsWith('v')) {
                    value = value.substring(1);
                    const supplier = item.supplier.code ? item.supplier.code.trim().toLowerCase() : null;
                    if (value && supplier == value) {
                        this.handleJob(item.job.code);
                        count++;
                    }
                } else if(value.startsWith('X') || value.startsWith('x')) {
                    value = value.substring(1);
                    const separator = '^#';
                    const separatorPos = value.indexOf(separator);
                    let version = null;
                    if(separatorPos >= 0) {
                        version = value.substring(separatorPos + separator.length);
                        value = value.substring(0, separatorPos);
                    }
                    if (value && item.job.code.trim().toLowerCase() == value && item.job.instruction.version == version) {
                        this.handleJob(item.job.code);
                        count++;
                    }
                }
            });
        }

        this.setState({error: !count});
    }


    handleHandScan() {
        setTimeout(() => {
            if(this.refs.code && this.refs.code.refs.handScan) {
                this.refs.code.refs.handScan.openScan(true);
            }
        }, 1000);
    }


    handleJob(jobCode) {
        let jobs = this.state.selectedJobs;
        if(jobs.indexOf(jobCode) >= 0) {
            jobs.splice(jobs.indexOf(jobCode), 1);
        } else {
            jobs.push(jobCode);
        }
        this.setState({selectedJobs: jobs});
    }


    handleSelect() {
        let partTitle = null;
        this.props.data.forEach((item) => {
            if(this.state.selectedJobs.indexOf(item.job.code) && item.part_name) {
                partTitle = item.part_name;
            }
        })
        this.props.onStart(this.state.selectedJobs, this.props.partNumber, partTitle);
    }


    render() {
        let rows = [];

        this.props.data.forEach((item, key) => {
            const selected = this.state.selectedJobs.indexOf(item.job.code) >= 0;
            rows.push(<tr key={key}>
                <td>{item.job.code}</td>
                <td>{item.job.title}</td>
                <td>{item.job.instruction.version}</td>
                <td>{item.supplier.title}</td>
                <td>{item.supplier.code}</td>
                <td>
                    <button className={classNames({smallButton: true, disabled: !selected, pointer: true})} onClick={() => this.handleJob(item.job.code)}>
                        <FaIcon icon={selected ? 'check' : 'times'} />
                    </button>
                </td>
            </tr>);
        });

        const selectedCount = this.state.selectedJobs.length;

        return <FlashMessage zIndex={1000} fullScreen={true} closeButton={true} okButton={true} okButtonTitle={_('Close')} type={C.flashMessages.ALERT}>
            <h3 className="scanHide">
                {_('Jobs for Part Number')}
                &nbsp;<i>{this.props.partNumber}</i>:
            </h3>
            <table className="jobsTable scanHide" border="1" cellPadding="10" cellSpacing="0">
                <tbody>
                <tr key="heading" className="headRow">
                    <th>{_('Job')}</th>
                    <th>{_('Title')}</th>
                    <th>{_('Version')}</th>
                    <th>{_('Supplier')}</th>
                    <th>{_('Code')}</th>
                    <th>{_('Select')}</th>
                </tr>
                {rows}
                </tbody>
            </table>
            <h3 className="scanHide" style={{marginTop: '1.5em', marginBottom: '0.7em'}}>
                {_('Job/Supplier Code')}
            </h3>
            <div className="formInput">
                <Input
                    ref="code"
                    required={true}
                    onChange={(value) => this.handleValue(value)}
                    convertChars={true}
                    handScan={true}
                    handScanOpen={true}
                    placeholder={_('Job/Supplier Code')}
                    onScanSubmit={() => this.handleHandScan()}
                    error={this.state.error ? _('Code not Found') : null}
                />
            </div>
            <button className={classNames({disabled: !selectedCount})} onClick={() => { this.handleSelect(); }} disabled={!selectedCount}>
                {_('Start with selected')}
                {
                    selectedCount > 0 &&
                    <span> ({selectedCount})</span>
                }
            </button>
        </FlashMessage>;
    }

}

