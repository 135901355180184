import React from 'react';
import FlashMessage from './flashMessage';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';
import {connect} from 'react-redux';


export default connect(
    (state, props) => {
        return {
            work: state.work,
            batch: state.batch,
        }
    }
)(
    class Stats extends React.Component {


        getBatchRow(batch, className = '') {
            let nok = 0;
            let reworked = 0;

            batch.defects.forEach((defect) => {
                nok += defect.nok;
                reworked += defect.reworked;
            });

            return <tr key={batch.id} className={className}>
                <td>{batch.batchNumber ? batch.batchNumber : '---'}</td>
                <td>{batch.amount}</td>
                <td>{batch.checked}</td>
                <td>{nok}</td>
                <td>{reworked}</td>
            </tr>;
        }


        render() {
            let rows = [];
            let sum = {
                amount: 0,
                checked: 0,
                defects: [],
            };

            if(this.props.work && this.props.work.finishedBatches) {
                this.props.work.finishedBatches.forEach((batch) => {
                    rows.push(this.getBatchRow(batch));
                    sum.amount += batch.amount;
                    sum.checked += batch.checked;
                    sum.defects = sum.defects.concat(batch.defects);
                });
            }

            if(this.props.batch) {
                let batch = JSON.parse(JSON.stringify(this.props.batch));
                batch.batchNumber = <span>{batch.batchNumber ? batch.batchNumber : '---'} <span className="empty">({ _('current')})</span></span>;
                rows.push(this.getBatchRow(batch));
                sum.amount += this.props.batch.amount;
                sum.checked += this.props.batch.checked;
                sum.defects = sum.defects.concat(batch.defects);

            }

            if(!rows.length) {
                rows.push(<tr key="no_batches"><td colSpan={5}><span className="empty">{_('No Batches Found')}</span></td></tr>);
            } else {
                sum.batchNumber = _('Summary');
                rows.push(this.getBatchRow(sum, 'sumRow'));
            }

            return <FlashMessage fullScreen={true} closeButton={true} okButton={true} type={C.flashMessages.COMMON}>
                <h3>{_('Stats for current work')}:</h3>
                <table className="statsTable" border="1" cellSpacing="0">
                    <tbody>
                        <tr key="heading" className="headRow">
                            <th>{_('Batch')}</th>
                            <th>{_('Total Count')}</th>
                            <th>{_('Checked')}</th>
                            <th>{_('NOKs')}</th>
                            <th>{_('Reworks')}</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </FlashMessage>;
        }

    }
)
