import React from 'react';
import Form from './form';
import Input from './input';
import * as C from '../utils/constants';
import {_} from '../locales/gettext';


export default class Part extends Form {

    static defaultProps = {
        submitText: _('Continue'),
        title: _('Part Number'),
    };

    render() {
        let inputs = <div>
            <Input
                ref="partNumber"
                placeholder={_('Part Number')}
                check={this.state.submitCheck}
                requiredPrefix={C.prefixes.PART_NUMBER}
                convertChars={true}
                handScan={true}
                handScanOpen={true}
                onScanSubmit={() => this.submit()}
            />
        </div>;

        return this.getForm(inputs);
    }

}
