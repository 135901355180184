import {_} from '../locales/gettext';

export default class Batch {

    constructor(currentState = null) {
        this.currentState = currentState;
        this.newState = JSON.parse(JSON.stringify(currentState));
    }


    initCurrentState(id, batchNumber, name, amount, defects, checked = 0, comment = '') {
        this.currentState =  {
            id: parseInt(id),
            batchNumber: batchNumber,
            name: name,
            amount: parseInt(amount),
            intruder: '',
            checked: parseInt(checked),
            defects: defects,
            comment: comment,
        };
        this.newState = JSON.parse(JSON.stringify(this.currentState));
    }


    getState() {
        return this.currentState;
    }


    validate() {
        let errors = [];
        let totalNok = 0;
        let totalReworked = 0;

        if(this.newState.amount <= 0) {
            errors.push(this.prepareError(null, _('Total amount is lower or equal 0'), 'amount'));
        }

        if(this.newState.checked < 0) {
            errors.push(this.prepareError(null, _('Checked is lower than 0'), 'checked'));
        }

        if(this.newState.checked > this.newState.amount) {
            errors.push(this.prepareError(null, _('Checked is higher than total amount'), 'checked'));
        }

        this.newState.defects.forEach((defect) => {
            if(defect.nok < 0) {
                errors.push(this.prepareError(defect.id, _('NOK is lower than 0')));
            } else {
                totalNok += defect.nok;
            }

            if(defect.reworked < 0) {
                errors.push(this.prepareError(defect.id, _('Reworked is lower than 0')));
            } else {
                totalReworked += defect.reworked;
            }

            if(defect.reworked > defect.nok) {
                errors.push(this.prepareError(defect.id, _('Reworked is higher than NOK')));
            }

            if(defect.nok > this.newState.checked) {
                errors.push(this.prepareError(defect.id, _('NOK is higher than checked')));
            }

            if(defect.nok > this.newState.amount) {
                errors.push(this.prepareError(defect.id, _('NOK is higher than total amount')));
            }
        });

        if(this.newState.amount < totalNok) {
            errors.push(this.prepareError(null, _('NOK sum is higher than total amount'), 'amount'));
        }

        if(this.newState.amount < totalReworked) {
            errors.push(this.prepareError(null, _('Reworked sum is higher than total amount'), 'amount'));
        }

        if(this.newState.checked < totalNok) {
            errors.push(this.prepareError(null, _('NOK sum is higher than checked'), 'checked'));
        }

        if(this.newState.checked < totalReworked) {
            errors.push(this.prepareError(null, _('Reworked sum is higher than checked'), 'checked'));
        }

        return errors;
    }


    getAffectedDefects() {
        let defects = [];

        this.currentState.defects.forEach((current) => {
            this.newState.defects.forEach((changed) => {
                if(
                    current.id == changed.id &&
                    (current.nok != changed.nok || current.reworked != changed.reworked)
                ) {
                    defects.push(current.id);
                }
            });
        });

        return defects;
    }


    populateNewState() {
        this.currentState = JSON.parse(JSON.stringify(this.newState));
        return this;
    }


    prepareError(defectId, message, field = null) {
        return {
            defect: defectId,
            message: message,
            field: field,
        }
    }


    addDefect(defectId, reworked = false, comment = null, documents = null, nok = true) {
        this.newState.defects.forEach((defect) => {
            if(defect.id == defectId) {
                if(nok) {
                    defect.nok++;
                }
                if(reworked) {
                    defect.reworked++;
                }
                if(comment) {
                    defect.comment += comment + ', ';
                }
                if(documents) {
                    if(!defect.documents) {
                        defect.documents = [];
                    }
                    defect.documents = defect.documents.concat(documents);
                }
            }
        });
        this.newState.checked++;
        return this;
    }

    addCheck(count = 1) {
        this.newState.checked += parseInt(count);
        return this;
    }


    setComment(comment) {
        this.newState.comment = comment;
        return this;
    }


    setAmount(count) {
        this.newState.amount = parseInt(count);
        return this;
    }


    setChecked(count) {
        this.newState.checked = parseInt(count);
        return this;
    }


    setNOK(defectId, count, comment = null, documents = null) {
        this.newState.defects.forEach((defect) => {
            if(defect.id == defectId) {
                defect.nok = parseInt(count);
                if(comment) {
                    defect.comment += comment + ', ';
                }
                if(documents) {
                    if(!defect.documents) {
                        defect.documents = [];
                    }
                    defect.documents = defect.documents.concat(documents);
                }
            }
        });
        return this;
    }


    setReworked(defectId, count) {
        this.newState.defects.forEach((defect) => {
            if(defect.id == defectId) {
                defect.reworked = parseInt(count);
            }
        });
        return this;
    }

}
