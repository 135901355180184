import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import FaIcon from './faIcon';
import {_} from '../locales/gettext';
import {convertChar} from '../utils/utils';


export default class HandScan extends React.Component {

    static propTypes = {
        onScan: PropTypes.func.isRequired,
        tooltip: PropTypes.string,
        keyboardTooltip: PropTypes.string,
        convertChars: PropTypes.bool,
        open: PropTypes.bool,
        placeholder: PropTypes.string,
        parseJSON: PropTypes.bool,
    };


    static defaultProps = {
        tooltip: _('Hand Scan'),
        placeholder: _('Code'),
        convertChars: false,
        open: false,
        parseJSON: false,
        keyboardTooltip: _('Type Code'),
    };


    constructor(props) {
        super(props);

        this.state = {
            openScan: props.open,
        };

        this.text = '';
        this.handleScan = this.handleScan.bind(this);
    }


    componentDidMount() {
        if(this.props.open) {
            document.addEventListener('keypress', this.handleScan);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.openScan) {
            document.addEventListener('keypress', this.handleScan);
        } else {
            document.removeEventListener('keypress', this.handleScan);
        }
    }


    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleScan);
    }


    handleScan(e) {
        if(e.key === 'Enter' || e.key === 'Tab') {
            if(this.props.parseJSON) {
                this.text = this.parseJSON(this.text);
            }
            this.props.onScan(this.text);
            this.openScan(false);
            this.text = '';
        } else {
            if(e.key.length === 1) {
                if(this.props.convertChars) {
                    this.text += convertChar(e.key);
                } else {
                    this.text += String.fromCharCode(e.keyCode);
                }
            }
        }
    }


    parseJSON(text) {
        return text
            .replace(/full%name/g, 'full_name')
            .replace(/2:2/g, '":"')
            .replace(/2,2/g, '","')
            .replace(/&2/g, '{"')
            .replace(/2}/g, '"}')
            .replace(/!"!/g, '":"')
            .replace(/!,!/g, '","')
            .replace(/\/!/g, '{"')
            .replace(/!\(/g, '"}');
    }


    openScan(open) {
        this.setState({
            openScan: open,
        });
    }


    handleChange(e) {
        if(e.key === 'Enter') {
            this.props.onScan(this.refs.input.value);
            this.openScan(false);
        }
    }


    render() {
        if(this.state.openScan) {
            this.text = '';
            return <div id="handScan" className="scanHide">
                <div className="text">
                    {_('Scan')} {this.props.placeholder}...
                </div>
                <span className="close" onClick={() => { this.openScan(false); }}>
                    <Tooltip content={this.props.keyboardTooltip}>
                        <FaIcon icon="keyboard" />
                    </Tooltip>
                </span>
            </div>;
        } else {
            return <Tooltip content={this.props.tooltip} className="target scanIcon2 scanHide">
                <span className="anchor iconAnchor" onClick={() => this.openScan(true)}>
                    <FaIcon icon="barcode" />
                </span>
            </Tooltip>;
        }
    }
}
